import React from 'react';
import Modal from 'react-modal';
import './PortfolioModal.css';

Modal.setAppElement('#root');

function applyGlitchEffect(text) {
    if (!text) return ''; // Return an empty string if text is undefined or null

    const glitchWords = ["end", "ruby", "software", "python", "efficiency", "application", "C#"];
    const regex = new RegExp(`(${glitchWords.join('|')})`, 'gi');
    return text.split(regex).map((part, index) => (
        glitchWords.some(glitchWord => glitchWord.toLowerCase() === part.toLowerCase()) ? (
            <span key={index} className="glitch-modal" data-text={part}>{part}</span>
        ) : part
    ));
}

function PortfolioModal({ isOpen, onRequestClose, content }) {
    const customStyles = {
        content: {
            background: 'black',
            color: 'white',
            width: '700px',
            height: '700px',
            margin: 'auto',
            boxShadow: 'none',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            padding: '20px',
            borderRadius: 'var(--border-radius)',
            outline: 'none',
        },
        overlay: {
            backgroundColor: 'rgba(0, 0, 0, 0)' // Fully transparent background
        }
    };

    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            contentLabel="Portfolio Detail"
            style={customStyles}
        >
            {content && (
                <>
                    <h2>{content.title ? applyGlitchEffect(content.title) : ''}</h2>
                    {/* Display the GIF for BMW item */}
                    {content.company_name === "BMW Manufacturing Co., LLC" && (
                        <div className="gif-container">
                            <img
                                src="https://i.gifer.com/4tz5.gif"
                                alt="GIF"
                                style={{ maxWidth: "150px", maxHeight: "150px" }} // Adjust size as needed
                            />
                        </div>
                    )} 
                    {content.company_name === "Freelance" && (
                        <div className="gif-container">
                            <img
                                src="images/ruby.gif"
                                alt="GIF"
                                style={{ maxWidth: "80px", maxHeight: "100px" }} // Adjust size as needed
                            />
                        </div>
                    )}
                    {content.company_name === "ups-supply-chain-solutions" && (
                        <div className="gif-container">
                            <img
                                src="images/ups.webp"
                                alt="GIF"
                                style={{ maxWidth: "220px", maxHeight: "150px" }} // Adjust size as needed
                            />
                        </div>
                    )}
                    {content.company_name === "chick-fil-a-supply" && (
                        <div className="gif-container">
                            <img
                                src="images/cfa.gif"
                                alt="GIF"
                                style={{ maxWidth: "150px", maxHeight: "130px" }} // Adjust size as needed
                            />
                        </div>
                    )}
                    {content.company_name === "costar" && (
                        <div className="gif-container">
                            <img
                                src="images/costar.gif"
                                alt="GIF"
                                style={{ maxWidth: "150px", maxHeight: "130px" }} // Adjust size as needed
                            />
                        </div>
                    )}
                    <div className="ReactModal__Content--Scrollable">
                        <p>{content.description ? applyGlitchEffect(content.description) : ''}</p>
                    </div>
                    <button onClick={onRequestClose}>Close</button>
                </>
            )}
        </Modal>
    );
}

export default PortfolioModal;
