// PortfolioItem.js
import React from 'react';
import './PortfolioItem.css';
import { useSpring, animated } from 'react-spring';

const applyGlitchEffect = (text) => {
  const glitchWords = ["Data", "Software", "Ruby", "app"];
  const regex = new RegExp(`(${glitchWords.join('|')})`, 'gi');
  return text.split(regex).map((part, index) => (
    glitchWords.some(glitchWord => glitchWord.toLowerCase() === part.toLowerCase()) ? (
      <span key={index} className="glitch" data-text={part}>{part}</span>
    ) : part
  ));
};

const truncateDescription = (description, maxLength) => {
    if (description.length <= maxLength) {
        return description;
    }

    const truncated = description.substr(0, maxLength);
    const lastSpaceIndex = truncated.lastIndexOf(' ');

    if (lastSpaceIndex !== -1) {
        return truncated.substr(0, lastSpaceIndex) + '...';
    } else {
        return truncated + '...';
    }
};

const PortfolioItem = ({ item, openModal }) => {
    const props = useSpring({ opacity: 1, from: { opacity: 0 } });
    const companyName = item.company_name.toLowerCase().replace(/[\s,]+/g, '-');
    const truncatedDescription = truncateDescription(item.description, 80);

    return (
        <animated.div
            style={props}
            className="portfolio-item"
            onClick={() => openModal(item)}
            data-company={companyName}
        >
            <div className="logo-container">
                <img
                    src={`${process.env.PUBLIC_URL}/${item.imageUrl}`}
                    alt={`${item.company_name} logo`}
                />
            </div>
            <h2 className="portfolio-title">{applyGlitchEffect(item.title)}</h2>
            <p className="portfolio-description">{applyGlitchEffect(truncatedDescription)}</p>
            <h1 className="portfolio-description">{applyGlitchEffect(item.date)}</h1>
        </animated.div>
    );
};

export default PortfolioItem;
