import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import PortfolioModal from './components/PortfolioModal';
import './Portfolio.css';
import PortfolioItem from './PortfolioItem';

function applyGlitchEffect(text) {
  const glitchWords = ["C#"];
  const regex = new RegExp(`(${glitchWords.join('|')})`, 'gi');
  return text.split(regex).map((part, index) => (
    glitchWords.some(glitchWord => glitchWord.toLowerCase() === part.toLowerCase()) ? (
      <span key={index} className="glitch-portfolio" data-text={part}>{part}</span>
    ) : part
  ));
}

function Portfolio() {
  const hardcodedData = [
      {
      id: 1,
      title: "Business Analyst/Programmer and Project Management Internship",
      date: "2017-2018",
      company_name: "BMW Manufacturing Co., LLC",
      description: "At BMW Manufacturing Co., LLC, I first worked as a Business Analyst & Programmer Intern, where I developed SQL queries and reports from an Oracle Database to support plant management. I created a live data visualization tool for upper management, integrated database views within SAP Business Objects, and designed user-friendly interfaces to improve data accessibility. Later, as a Project Management Intern, I analyzed production data for the X7 model, identifying potential issues and enhancing recordkeeping in Excel. I collaborated with plant managers to reduce production downtime and managed communications to address delays, part defects, and assembly errors, contributing to more efficient operations.",
      tech_stack: ["Excel", "SQL", "SAP BO"],
      imageUrl: "images/bmw.png",
    },
    {
      id: 2,
      title: "Freelance Full-Stack Developer",
      date: "2017-2018",
      company_name: "Freelance",
      description: "As a self-employed Freelance Full-Stack Developer based in Atlanta, Georgia, from July 2017 to February 2018, I embarked on a significant project for a blogger. This endeavor involved the comprehensive development of a website using Ruby on Rails, enabling image uploads and fostering user collaboration through comments. Leveraging the capabilities of PostgreSQL and AWS S3, I meticulously designed the architecture to efficiently store and retrieve data. My development approach was grounded in the MVC (Model-View-Controller) architecture, ensuring a well-structured and user-friendly website that catered to the blogger's specific requirements.",
      tech_stack: ["Ruby on Rails", "PostgreSQL", "AWS"],
      imageUrl: "images/upwork.png",
    },
    {
      id: 3,
      title: "Senior Application Developer",
      date: "2018-2021",
      company_name: "ups-supply-chain-solutions",
      description: "At UPS, I contributed to enhancing their largest global import/export application by developing key features for parts classification, improving the front end with Angular 7, and using C# for back-end development. I identified and fixed bugs across both ends, ensuring optimal performance and collaborated with cross-functional teams to advance the project. Earlier, as an Application Developer, I led the development of a web application using ASP.Net MVC that provided real-time tracking insights for major clients like Nike by integrating APIs from UPS, FedEx, and DHL. I managed the full SDLC, from API design to deployment, and incorporated Agile methodology throughout the process.",
      tech_stack: ["Angular 7", "C#", "API"],
      imageUrl: "images/ups.png",
    },
    {
      id: 4,
      title: "Software Engineer III Analytics",
      date: "2021-2024",
      company_name: "chick-fil-a-supply",
      description: "I’ve been instrumental in driving innovation in data analytics at Chick-fil-A Supply by developing advanced ETL workflows using Python and Airflow for AWS Redshift, significantly improving data processing and efficiency. I optimized the Redshift data warehouse to enhance decision-making support and led a cost-efficient migration from Oracle to Redshift. Additionally, I automated AWS infrastructure with Terraform, built versatile ETL pipelines for both structured and semi-structured data, and integrated the Snowflake cloud platform, advancing data extraction, transformation, and business analytics capabilities.",
      tech_stack: ["Python", "AWS", "Airflow", "Redshift"],
      imageUrl: "images/cfa.png",
    },
    {
      id: 5,
      title: "Senior Data Engineer",
      date: "2024-Current",
      company_name: "costar",
      description: "At CoStar, I am currently designing and optimizing data pipelines using AWS services like S3, RDS, and DynamoDB to ensure seamless data flow and transformation. I’m actively building and maintaining ETL/ELT processes with SQL Replication, integrating data from multiple sources to provide real-time insights for key teams. I collaborate with analytics, data science, and business teams to ensure data is accessible, accurate, and aligned with business needs. Additionally, I focus on performance optimization and managing data infrastructure to ensure scalability, efficiency, and data integrity across all systems.",
      tech_stack: ["Python", "AWS", "SQL"],
      imageUrl: "images/costar.png",
    }
  ];

  const [filteredItems, setFilteredItems] = useState(hardcodedData);
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState(null);
  const [filter, setFilter] = useState('All');

  useState(() => {
    setFilteredItems(filter === 'All' ? hardcodedData : hardcodedData.filter(item => item.tech_stack.includes(filter)));
  }, [filter, hardcodedData]);

  const openModal = (item) => {
    setSelectedItem(item);
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
    setFilteredItems(newFilter === 'All' ? hardcodedData : hardcodedData.filter(item => item.tech_stack.includes(newFilter)));
  };

  return (
    <>
      <Helmet>
        <title>Khaled Asad Portfolio</title>
        <meta name="description" content="Showcase of professional work and projects." />
      </Helmet>
      <div className="portfolio-container">
        <div className="portfolio-filters">
          <button onClick={() => handleFilterChange('All')}>All</button>
          <button onClick={() => handleFilterChange('Python')}>Python</button>
          <button onClick={() => handleFilterChange('C#')}>{applyGlitchEffect('C#')}</button>
          <button onClick={() => handleFilterChange('AWS')}>AWS</button>
        </div>
        <div className="portfolio-items-container">
          {filteredItems.map((item) => (
            <PortfolioItem
              key={item.id}
              item={item}
              openModal={openModal}
            />
          ))}
        </div>
        <PortfolioModal
          isOpen={modalIsOpen}
          onRequestClose={closeModal}
          content={selectedItem || {}}
        />
      </div>
    </>
  );
}

export default Portfolio;
