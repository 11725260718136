import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Home.css';
import worldGif from './world.gif';

function Home() {
    const navigate = useNavigate();

    const navigateToPortfolio = () => {
        navigate('/portfolio');
    };

    return (
        <div className="home-container">
            <img src={worldGif} alt="Animated Preview" className="centered-image" />
            <h1 className="glitch" data-text="Hello World!">Hello World!</h1>
            <p className="intro-text">
                {/* Add any intro text here if needed */}
            </p>
            <div className="cta-container">
                <button onClick={navigateToPortfolio} className="cta">
                    View My Work
                </button>
            </div>
        </div>
    );
}

export default Home;
