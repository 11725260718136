import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import './Contact.css'; // Import the Contact-specific CSS file
import emailjs from 'emailjs-com';

function Contact() {
    const [formData, setFormData] = useState({ name: '', email: '', message: '' });
    const [submitted, setSubmitted] = useState(false);
    const [error, setError] = useState('');

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Use emailjs to send the email
        emailjs.send('service_ra96bsr', 'template_8wfjazv', formData, 'ZxNCbfGTdfZ9e4zZs') // Replace with your credentials
            .then((response) => {
                setSubmitted(true);
                setError('');
                setFormData({ name: '', email: '', message: '' });
            })
            .catch((error) => {
                setError('There was a problem submitting your form. Please try again.');
                setSubmitted(false);
            });
    };

    const handleDownloadResume = () => {
        // Replace 'your_resume.pdf' with the actual file path of your resume.
        const resumeFile = 'https://khaledasad-bucket.s3.amazonaws.com/Khaled_Asad_Resume.pdf';

        // Open the URL in a new tab
        window.open(resumeFile, '_blank');
    };


    return (
        <>
            <Helmet>
                <title>Contact Me</title>
                <meta name="description" content="Reach out to me via the contact form." />
            </Helmet>
            <div className="contact-container">
                <h1 className="glitch" data-text="Contact Me 👀">
                    Reach out
                </h1>
                {submitted && <p className="success-message">Thank you for your message!</p>}
                {error && <p className="error-message">{error}</p>}
                <form onSubmit={handleSubmit}>
                    <input type="text" name="name" value={formData.name} onChange={handleChange} placeholder="Your Name" required />
                    <input type="email" name="email" value={formData.email} onChange={handleChange} placeholder="Your Email" required />
                    <textarea name="message" value={formData.message} onChange={handleChange} placeholder="Your Message" required />
                    <button className="button" type="submit">
                        Send Message
                    </button>
                    <button className="download-button" type="button" onClick={handleDownloadResume}>
                        Download Resume 🧾
                    </button>
                </form>
                {/* Contact Information */}
                <div className="contact-info">
                    <p>Email: <a href="mailto:khaledasad94@outlook.com">khaledasad94@outlook.com</a></p>
                    <p>
                        LinkedIn: <a href="https://www.linkedin.com/in/khaled-asad/" target="_blank" rel="noopener noreferrer">Khaled Asad</a>
                    </p>
                </div>
            </div>
        </>
    );
}

export default Contact;
