import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './Common.css';

import backgroundVideo from './grid.mp4';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <div style={{ position: 'relative', zIndex: 0 }}>
      <video
        autoPlay
        loop
        muted
        style={{
          position: "absolute",
          width: "100%",
          left: "50%",
          top: "0",
          minHeight: "100%",
          objectFit: "cover",
          transform: "translate(-50%, 0)",
          zIndex: "-1"
        }}
      >
        <source src={backgroundVideo} type="video/mp4" />
      </video>
      <App />
    </div>
  </React.StrictMode>
);

reportWebVitals();
