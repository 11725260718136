import React from 'react';
import { useSpring, animated } from 'react-spring';
import { Helmet } from 'react-helmet';
import './About.css';

function applyGlitchEffect(text) {
  const glitchWords = ["Data", "Software", "Српски", "Senidah", "Frostpunk", "Database", "citizen", "u", "Returnal", "Warzone", "Tame"];
  const regex = new RegExp(`(${glitchWords.join('|')})`, 'gi');
  return text.split(regex).map((part, index) => (
    glitchWords.some(glitchWord => glitchWord.toLowerCase() === part.toLowerCase()) ? (
      <span key={index} className="glitch" data-text={part}>{part}</span>
    ) : part
  ));
}

const Collapsible = ({ title, children }) => {
  const [isOpen, setIsOpen] = React.useState(false);

  const toggleCollapsible = () => setIsOpen(!isOpen);

  return (
    <div className="collapsible">
      <button className="collapsible-button" onClick={toggleCollapsible}>
        {title}
      </button>
      <div className={`collapsible-content ${isOpen ? 'open' : ''}`}>
        {children}
      </div>
    </div>
  );
};

function About() {
  const bio = `
      I'm a seasoned tech leader with a proven track record of driving data-driven innovation.
      As Senior Data Engineer💻 at CoStar, I lead the shift to AWS cloud, optimizing data pipelines that power strategic decisions. 
      Previously at Chick-fil-A Supply, I automated AWS infrastructure and optimized large-scale data processes, boosting operational efficiency across the supply chain. 
      As Senior Full Stack Developer at UPS, I developed key features for global logistics platforms, integrating multiple carrier systems into a seamless solution that enhanced service delivery. 
      With a Master's in Big Data and Analytics and a Bachelor's in Software Development, I combine deep technical expertise with a passion for delivering scalable, high-impact solutions. 🚀
      `;
  
  const skills = [
    {
      category: "Languages",
      icon: "💻",
      list: [
        { name: "Python🐍", level: 90 },
        { name: "C#", level: 80 },
        { name: "SQL", level: 85 }
      ]
    },
    {
      category: "Cloud",
      icon: "☁️",
      list: [
        { name: "AWS (S3, Redshift, RDS)", level: 80 },
        { name: "Terraform🧱", level: 75 },

      ]
    },
    {
      category: "Tools",
      icon: "🔧",
      list: [
        { name: "Airflow/MWAA", level: 80 },
        { name: "SQL Replication", level: 75 },
        { name: "Pandas🐼", level: 85 },
      ]
    },
    // Methodologies block with a 2x2 grid layout
    {
      category: "Methodologies",
      icon: "🛠️",
      className: "methodologies", // Add this class for custom grid
      list: [
        { name: "Kanban", level: 85 },
        { name: "Agile🤸🏼‍♂️", level: 90 },
        { name: "JIRA", level: 80 },
        { name: "TFS", level: 75 }
      ]
    }
  ];
  
  const education = [
    {
      degree: "Master of Science in Information Systems (Big Data & Analytics)",
      school: "Georgia State University",
      year: "2021",
      details: "Specialized in Big Data and Analytics, focusing on large-scale data infrastructure, cloud computing, and advanced data programming."
    },
    {
      degree: "Bachelor of Science in Software Development, Cum Laude",
      school: "Georgia Gwinnett College",
      year: "2017",
      details: "Graduated with honors, gaining a strong foundation in software engineering, system architecture, and full-stack development."
    }
  ];
  
  
  const certifications = [
    { name: 'AWS Certified Solutions Architect – Associate', badge_url: 'https://images.credly.com/size/340x340/images/0e284c3f-5164-4b21-8660-0d84737941bc/image.png' },
    { name: 'AWS Certified Database – Specialty', badge_url: 'https://images.credly.com/size/340x340/images/885d38e4-55c0-4c35-b4ed-694e2b26be6c/image.png' }
  ];

  const languages = [
    { name: "Hrvatski", flag_image: "https://gallery.yopriceville.com/var/resizes/National-Flags/Croatia_Large_Flag.png?m=1441188629" },
    { name: "Српски", flag_image: "https://cdn.britannica.com/39/94839-004-EEB20F36/Flag-Serbia.jpg" },
    { name: "عربي", flag_image: "https://qph.cf2.quoracdn.net/main-qimg-c628f557e989248eca1467b5ee0fd070" },
    { name: "English", flag_image: "https://cdn.britannica.com/33/4833-004-828A9A84/Flag-United-States-of-America.jpg" }
  ];

  const music = [
    { name: "KÖFN", album_image: "https://i.scdn.co/image/ab67616d0000b273aeca6ed869eca5f1b7fb1022" },
    { name: "Senidah", album_image: "https://cdns-images.dzcdn.net/images/cover/5ef77d9cff1c57cd4e78b7f05889d9e3/264x264.jpg" },
    { name: "GIMS", album_image: "https://cdns-images.dzcdn.net/images/cover/2fd4c4b87777b905ea7cc5787d9032ce/264x264.jpg" },
    { name: "Roosevelt", album_image: "https://upload.wikimedia.org/wikipedia/en/c/c2/Roosevelt_-_Polydans.png" },
    { name: "Lomepal", album_image: "https://static.fnac-static.com/multimedia/Images/FR/NR/ae/ef/df/14675886/1540-1/tsp20220829094109/Mauvais-Ordre.jpg" },
    { name: "Eagle Eyed Tiger", album_image: "https://i.discogs.com/bd6WZ36jRpQMbOb_aVRM2sPWDEb41qP_o7gtocByO-0/rs:fit/g:sm/q:90/h:600/w:600/czM6Ly9kaXNjb2dz/LWRhdGFiYXNlLWlt/YWdlcy9SLTE2MTM4/Nzg4LTE2MDQwODQz/MjktMzE2Ny5qcGVn.jpeg" },
    { name: "Nek", album_image: "https://i.ebayimg.com/images/g/7oUAAOSwKYtjkHTe/s-l400.jpg" },
    { name: "Tame Impala", album_image: "https://i1.sndcdn.com/avatars-yHA8nds2mqg4uYtr-kyxTzw-t500x500.jpg" },
    { name: "ODESZA", album_image: "https://media0.giphy.com/media/7JmNRaP97EY8skOu8k/200w.gif?cid=82a1493b4qzl1vrv9cuhe09qwqrmxao5p5vsxndsk4ki4jce&ep=v1_gifs_related&rid=200w.gif&ct=g" },
    { name: "TR/ST", album_image: "https://f4.bcbits.com/img/a3624484487_65" }
  ];

  const citizenships = [
    { name: "Croatian", flag_image: "images/croatia.png" },
    { name: "Serbian", flag_image: "https://cdn.britannica.com/39/94839-004-EEB20F36/Flag-Serbia.jpg" },
    { name: "USA", flag_image: "https://cdn.britannica.com/33/4833-004-828A9A84/Flag-United-States-of-America.jpg" }
  ];

  const games = [
    { name: "Return to Castle Wolfenstein", game_image: "https://preview.redd.it/e1u4dxl47ue61.gif?width=192&auto=webp&s=24dd12f5e5e76f088c7ea9d788deb0ddf86d0a83" },
    { name: "MGS5", game_image: "https://upload.wikimedia.org/wikipedia/en/8/8f/Metal_Gear_Solid_V_The_Phantom_Pain_cover.png" },
    { name: "Frostpunk", game_image: "https://steamuserimages-a.akamaihd.net/ugc/954089084711664779/8D2A257F0D34D8399F2383723FB187DE24896E59/?imw=637&imh=358&ima=fit&impolicy=Letterbox&imcolor=%23000000&letterbox=true" },
    { name: "Returnal", game_image: "https://steamuserimages-a.akamaihd.net/ugc/1777209780716567923/D1699A197086E9A69DA7F527404E7CC74C48CB67/?imw=637&imh=358&ima=fit&impolicy=Letterbox&imcolor=%23000000&letterbox=true" },
    { name: "Skyrim", game_image: "https://assets-prd.ignimgs.com/2021/08/19/elder-scrolls-skyrim-button-2017-1629409446732.jpg" },
    { name: "Civilization V", game_image: "https://upload.wikimedia.org/wikipedia/en/5/5c/CIVILIZATION-V-FRONT-OF-BOX.jpg" },
    { name: "Total War Series", game_image: "https://m.media-amazon.com/images/I/8128PP+ksOL.jpg" },
    { name: "Tom Clancy's Splinter Cell", game_image: "https://m.media-amazon.com/images/M/MV5BN2EyNTMzY2YtMTM2My00M2FjLTliZDItODU1MjJmMzE5OTRiXkEyXkFqcGdeQXVyMTA0MTM5NjI2._V1_.jpg" },
    { name: "Call of Duty: Warzone", game_image: "http://t1.gstatic.com/images?q=tbn:ANd9GcTSFMxoFoAH6qTRt2VQb5P4G19gSmYAy1gm9BBZ6PVpLShfrg9tTn68AjEpW5YC1nm-iu7N" },
  ];

  const fadeInProps = useSpring({ opacity: 1, from: { opacity: 0 } });

  return (
    <animated.div style={fadeInProps} className="about-container">
      <Helmet>
        <title>About Me</title>
      </Helmet>

      <div className="about-main">
        <div className="about-image-container">
          <img src="images/linkedin.jpg" alt="Your Name" className="about-image" />
        </div>

        <Collapsible title="Bio">
          <p>{bio}</p>
        </Collapsible>

        <Collapsible title="Skills">
          <div className="skills-container">
            {skills.map((skillCategory, index) => (
              <div key={index} className="skills-card">
                <h3 className="skills-title">{skillCategory.icon} {skillCategory.category}</h3>
                <ul className="skills-list">
                  {skillCategory.list.map((skill, idx) => (
                    <li key={idx} className="skill-item">
                      <div className="skill-label">{skill.name}</div>
                      <div className="skill-progress">
                        <div className="skill-progress-bar" data-skill-level={skill.level}></div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </Collapsible>

        <Collapsible title="Education">
          <div className="education-container">
            {education.map((edu, index) => (
              <div key={index} className="education-card">
                <div className="education-header">
                  <h3 className="education-degree">{edu.degree}</h3>
                  <p className="education-school">
                    {edu.school} — {edu.year}
                  </p>
                </div>
                <p className="education-description">{edu.details}</p>
              </div>
            ))}
          </div>
        </Collapsible>



        <Collapsible title="Certifications">
          <div className="certifications-container">
            {certifications.map((cert, index) => (
              <div key={index} className="certification-card">
                <img src={cert.badge_url} alt={cert.name} className="certification-badge" />
                <p>{applyGlitchEffect(cert.name)}</p>
              </div>
            ))}
          </div>
        </Collapsible>

        <Collapsible title="Languages">
          <div className="certifications-container">
            {languages.map((language, index) => (
              <div key={index} className="certification-card">
                <img src={language.flag_image} alt={language.name} className="language-badge" />
                <p>{applyGlitchEffect(language.name)}</p>
              </div>
            ))}
          </div>
        </Collapsible>

        <Collapsible title={applyGlitchEffect("Music")}>
          <div className="certifications-container">
            {music.map((mus, index) => (
              <div key={index} className="certification-card">
                <img src={mus.album_image} alt={mus.name} className="music-badge" />
                <p>{applyGlitchEffect(mus.name)}</p>
              </div>
            ))}
          </div>
        </Collapsible>

        <Collapsible title={applyGlitchEffect("Citizenships")}>
          <div className="certifications-container">
            {citizenships.map((citizenship, index) => (
              <div key={index} className="certification-card">
                <img src={citizenship.flag_image} alt={citizenship.name} className="citizenship-badge" />
                <p>{applyGlitchEffect(citizenship.name)}</p>
              </div>
            ))}
          </div>
        </Collapsible>

        <Collapsible title="Games">
          <div className="certifications-container">
            {games.map((game, index) => (
              <div key={index} className="certification-card">
                <img src={game.game_image} alt={game.name} className="game-badge" />
                <p>{applyGlitchEffect(game.name)}</p>
              </div>
            ))}
          </div>
        </Collapsible>

      </div>
    </animated.div>
  );
}

export default About;
